body {
    margin-top: 50px;
    background-color: #fff;
    font-family: Arial, sans-serif;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: #39464e;
  }
  
  .navbar-default {
    background-color: #fff;
    margin-left: 200px;
  }
  
  /*main side bar*/
  .msb {
    width: 300px;
    background-color: #f5f7f9;
    position: fixed;
    left: 0;
    top: 0;
    right: auto;
    min-height: 100%;
    overflow-y: auto;
    white-space: nowrap;
    height: 100%;
    z-index: 1;
    border-right: 1px solid #ddd;
  }
    .msb .navbar {
      border: none;
      margin-left: 0;
      background-color: inherit;
    }
    .msb .navbar-header {
      width: 100%;
      border-bottom: 1px solid #e7e7e7;
      margin-bottom: 20px;
      background: #fff;
    }
    .navbar-nav .panel {
        border: 0 none;
        box-shadow: none;
        margin: 0;
        background: inherit;
    }
    .navbar-nav  li {
        display: block;
        width: 100%;
    }
    .navbar-nav a{
        padding: 15px;
        color: #5f5f5f;
    }
    .navbar-nav a .glyphicon .fa {
            margin-right: 8px;
    }
    .msb .nb {
      padding-top: 5px;
      padding-left: 10px;
      margin-bottom: 30px;
      overflow: hidden;
    }
  
  ul.nv,
  ul.ns {
    position: relative;
    padding: 0;
    list-style: none;
  }
  .nv li{
      display: block;
      position: relative;
  }
  .nv li::before{
      clear: both;
      content: " ";
      display: table;
  }
  .nv li a{
    color: #444;
    padding: 10px 25px;
    display: block;
  }
  .nv li a .ic{
    font-size: 16px;
    margin-right: 5px;
    font-weight: 300;
    display: inline-block;
  }
  .nv .ns li a {
      padding: 10px 50px;
  }
 
  
  /*main content wrapper*/
  .mcw {
    margin-left: 200px;
    position: relative;
    min-height: 100%;
  }
  .mcw a a:focus a:hover{
      text-decoration: none;
  }
  .inbox .container-fluid{
      padding-left: 0;
      padding-right: 0;
  }
  .inbox ul li {
      margin: 0;
      padding: 0;
  }
  .inbox ul li{
      list-style: none;
  }
  .inbox ul li a {
      display: block;
      padding: 10px 20px;
  }
  
  .msb,
  .mnb {
    -moz-animation: slidein 300ms forwards;
    -o-animation: slidein 300ms forwards;
    -webkit-animation: slidein 300ms forwards;
    animation: slidein 300ms forwards;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  .mcw {
    -moz-animation: bodyslidein 300ms forwards;
    -o-animation: bodyslidein 300ms forwards;
    -webkit-animation: bodyslidein 300ms forwards;
    animation: bodyslidein 300ms forwards;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  bosy .msb-x .mcw .mnb{
    margin-left: 0;
    -moz-animation: bodyslideout 300ms forwards;
    -o-animation: bodyslideout 300ms forwards;
    -webkit-animation: bodyslideout 300ms forwards;
    animation: bodyslideout 300ms forwards;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }

  body.msb-x .msb {
      -moz-animation: slideout 300ms forwards;
      -o-animation: slideout 300ms forwards;
      -webkit-animation: slideout 300ms forwards;
      animation: slideout 300ms forwards;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
    
  }
  
  /* Slide in animation */
  @-moz-keyframes slidein {
    0% {
      left: -200px;
    }
    100% {
      left: 0;
    }
  }
  @-webkit-keyframes slidein {
    0% {
      left: -200px;
    }
    100% {
      left: 0;
    }
  }
  @keyframes slidein {
    0% {
      left: -200px;
    }
    100% {
      left: 0;
    }
  }
  @-moz-keyframes slideout {
    0% {
      left: 0;
    }
    100% {
      left: -200px;
    }
  }
  @-webkit-keyframes slideout {
    0% {
      left: 0;
    }
    100% {
      left: -200px;
    }
  }
  @keyframes slideout {
    0% {
      left: 0;
    }
    100% {
      left: -200px;
    }
  }
  
  @-moz-keyframes bodyslidein {
    0% {
      left: 0;
    }
    100% {
      margin-left: 200px;
    }
  }
  @-webkit-keyframes bodyslidein {
    0% {
      left: 0;
    }
    100% {
      left: 0;
    }
  }
  @keyframes bodyslidein {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: 200px;
    }
  }
  @-moz-keyframes bodyslideout {
    0% {
      margin-left: 200px;
    }
    100% {
      margin-right: 0;
    }
  }
  @-webkit-keyframes bodyslideout {
    0% {
      margin-left: 200px;
    }
    100% {
      margin-left: 0;
    }
  }
  @keyframes bodyslideout {
    0% {
      margin-left: 200px;
    }
    100% {
      margin-left: 0;
    }
  }


/*Display page*/
.container-fluid{
  position: relative;
  left: 10%;
}
.todo-card{
  width: 300px;
  height: 600px;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  left: 2%;
  top: 2%;
}

.rectangle1{
  width: 250px;
  height: 70px;
  border-left: 4px solid #00C408;
  margin-left: 0.6rem;
}
.rectangle1-heading{
  font-size: 25px;
  font-weight: 600;
  line-height: 33px;
}
.fa-angle-left{
  font-size: 25px;
  color: #000000;
  opacity: 80%;
}
.rectangle2{
  width: 250px;
  height: 270px;
  border-left: 4px solid #00C408;
  margin-left: 0.6rem;
  margin-top: 0.6rem;
}

.rectangle2-info{
  display: flex;
  flex-direction: column;
}
.fa-circle{
  font-size: 30px;
  color: #00C408;
}
.dp-grp{
  display: flex;
  flex-direction: row;
  margin-top: -0.7rem;
  margin-left: 7rem;
}
.icon-grp{
  display: flex;
  flex-direction: row;
  margin-top: -0.4rem;
}
.rectangle3{
  width: 250px;
  height: 55px;
  margin-left: 0.6rem;
  margin-top: 0.6rem;
  border-left: 4px solid #00C408;
}
.rectangle3-heading{
  font-size: 14px;
  color: #000000;
  opacity: 60%;
}
.rectangle4{
  width: 250px;
  height: 120px;
  margin-left: 0.6rem;
  margin-top: 0.6rem;
  border-left: 4px solid #00C408;
}
.buttons{
  margin-top: 0.6rem;
  margin-left: 0.6rem;
}
.btn-light{ 
  background: #FFFFFF;
  border: 0.3px solid #BDBDBD;
  box-sizing: border-box;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
}
.doing-card{
  width: 300px;
  height: 500px;
  position: absolute;
  left: 30%;
  top: 2%;
  background: #FFFFFF;
  border-radius: 4px;
}
.circle-icon{
  display: flex;
  flex-direction: row;
}
.question-card{
  width: 300px;
  height: 260px;
  position: absolute;
  left: 58%;
  top: 2%;
  background: #FFFFFF;
  border-radius: 4px;
}

/*Sidebar*/
.brand{
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}
.img-grp{
  position: relative;
  left: 80%;
}